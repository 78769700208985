import React, { useEffect, useState } from 'react'
// import { FaPlus } from "react-icons/fa";
import { ImPlus } from 'react-icons/im'
import NavTitle from './NavTitle'

const Category = ({ manageCategoryId }) => {
  const [showSubCatOne, setShowSubCatOne] = useState(false)
  const [idHolder, setIdholder] = useState(0)

  const manageCategory = (id) => {
    const element = document.getElementById(id)

    if (idHolder === id) {
      element.style.backgroundColor = ''
      element.style.color = ''
      manageCategoryId(0)
      setIdholder(0)
    } else {
      if (idHolder != 0) {
        const element2 = document.getElementById(idHolder)
        element2.style.backgroundColor = ''
        element2.style.color = ''
      }
      element.style.backgroundColor = 'rgb(170,170,166)'

      element.style.color = 'white'
      manageCategoryId(id)
      setIdholder(id)
    }
  }
  const items = [
    {
      _id: 990,
      title: 'Electronics',
      icons: true,
    },
    {
      _id: 991,
      title: 'Home Decor',
    },
    {
      _id: 992,
      title: 'Travel & Accessories',
      icons: true,
    },
    {
      _id: 993,
      title: 'Kitchen & Appliances',
    },
    {
      _id: 994,
      title: 'Furniture & Organization',
    },
  ]
  return (
    <div className="w-full">
      <NavTitle title="Shop by Category" icons={false} />
      <div>
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
          {items.map(({ _id, title }) => (
            <button
              key={_id}
              id={_id}
              className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300 text-xl py-2 px-1 rounded-lg shadow-sm"
              onClick={() => manageCategory(_id)}
            >
              {title}
            </button>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Category
