import React from 'react'
import Heading from '../Products/Heading'
import Product from '../Products/Product'
import { spfOne, spfTwo, spfThree, spfFour } from '../../../assets/images/index'

const SpecialOffers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Special Offers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1101"
          img={spfOne}
          brandName="Thor Cap"
          category="Travel & Accessories"
          productName="Thor Logo Limited Edition Kids Cap"
          price="35.00"
          color="Black and White"
          badge={false}
          des="A stylish and limited edition Thor-themed snapback cap designed for children, featuring a grey and black color scheme with UV protection. The cap boasts the iconic Thor logo, making it a fun and practical accessory for young fans."
        />
        <Product
          _id="1102"
          img={spfTwo}
          brandName="Danpinera"
          category="Furniture & Organization"
          productName="Danpinera Round Side Table"
          price="79.00"
          color="Wood"
          badge={false}
          des="Transform your tea-time experience with 'Danpinera Round Side Table' an exquisite online destination for premium tea accessories and curated tablescape collections. Explore our curated selection of fine teas, elegant tableware, and accessories, creating the perfect ambiance for your tea gatherings. Elevate your tea rituals with Danpinera."
        />
        <Product
          _id="1103"
          img={spfThree}
          productName="Anker Soundcore Life Q30 Headphones"
          brandName="Anker"
          category="Electronics"
          price="99.00"
          color="Black"
          badge={false}
          des="The Anker Soundcore Life Q30 headphones offer an immersive audio experience with hybrid active noise cancellation, utilizing dual microphones to eliminate up to 95% of low-frequency ambient noise. Users can tailor the noise cancellation to specific environments, and the headphones boast Hi-Res 40mm drivers for exceptional audio clarity up to 40kHz. With an impressive 40-hour playtime in noise-canceling mode, customizable ANC modes, and pressure-free comfort with memory foam earcups, the Life Q30 provides a premium and versatile listening solution for extended periods."
        />
        <Product
          _id="1104"
          img={spfFour}
          productName="Ray-Ban RB4184 Square Sunglasses, Black"
          brandName="Ray-Ban"
          category="Travel & Accessories"
          price="89.00"
          color="Black"
          badge={false}
          des="The Ray-Ban RB4184 Square Sunglasses in Black/Green, with a 54 mm lens size, offer a restructured take on the iconic Wayfarer design. Featuring signature temples and a larger rectangular shape, these sunglasses are not only stylish but also prescription-ready, providing both fashion and functionality for any wearer."
        />
      </div>
    </div>
  )
}

export default SpecialOffers
