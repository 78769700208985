import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import Product from '../../home/Products/Product'
import { paginationItems } from '../../../constants'

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div key={item._id} className="w-full">
            <Product
              _id={item._id}
              img={item.img}
              productName={item.productName}
              category={item.category}
              price={item.price}
              color={item.color}
              badge={item.badge}
              des={item.des}
            />
          </div>
        ))}
    </>
  )
}

function priceFilter(items, priceId) {
  if (priceId == 950) {
    items = items.filter((item) => parseFloat(item.price) < 49.99)
  } else if (priceId == 951) {
    items = items.filter(
      (item) => parseFloat(item.price) >= 50 && parseFloat(item.price) < 99.99
    )
  } else if (priceId == 952) {
    items = items.filter(
      (item) => parseFloat(item.price) >= 100 && parseFloat(item.price) < 199.99
    )
  } else if (priceId == 953) {
    items = items.filter(
      (item) => parseFloat(item.price) >= 200 && parseFloat(item.price) < 399.99
    )
  } else if (priceId == 954) {
    items = items.filter(
      (item) => parseFloat(item.price) >= 400 && parseFloat(item.price) < 599.99
    )
  } else if (priceId == 955) {
    items = items.filter(
      (item) => parseFloat(item.price) >= 600 && parseFloat(item.price) < 1000
    )
  }
  return items
}

function categoryFilter(items, categoryId) {
  if (categoryId == 990) {
    items = items.filter((item) => item.category == 'Electronics')
  } else if (categoryId == 991) {
    items = items.filter((item) => item.category == 'Home Decor')
  } else if (categoryId == 992) {
    items = items.filter((item) => item.category == 'Travel & Accessories')
  } else if (categoryId == 993) {
    items = items.filter((item) => item.category == 'Kitchen & Appliances')
  } else if (categoryId == 994) {
    items = items.filter((item) => item.category == 'Furniture & Organization')
  }
  return items
}
function BrandFilter(items, brandId) {
  if (brandId == 9006) {
    items = items.filter((item) => item.brandName == 'Apple')
  } else if (brandId == 9007) {
    items = items.filter((item) => item.brandName == 'Samsung')
  } else if (brandId == 9008) {
    items = items.filter((item) => item.brandName == 'Anker')
  } else if (brandId == 9009) {
    items = items.filter((item) => item.brandName == 'Logitech')
  } else if (brandId == 9010) {
    items = items.filter(
      (item) =>
        item.brandName != 'Apple' &&
        item.brandName != 'Samsung' &&
        item.brandName != 'Anker' &&
        item.brandName != 'Logitech'
    )
  }
  return items
}

const Pagination = ({ itemsPerPage, priceId, categoryId, brandId }) => {
  let items = categoryFilter(paginationItems, categoryId)
  items = priceFilter(items, priceId)
  items = BrandFilter(items, brandId)
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0)
  const [itemStart, setItemStart] = useState(1)

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(items.length / itemsPerPage)

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length
    setItemOffset(newOffset)
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset},`
    // );
    setItemStart(newOffset)
  }

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        <Items currentItems={currentItems} />
      </div>
      <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
        <ReactPaginate
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel=""
          pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
          pageClassName="mr-6"
          containerClassName="flex text-base font-semibold font-titleFont py-10"
          activeClassName="bg-black text-white"
        />
        <p className="text-base font-normal text-lightText">
          Products from {itemStart === 0 ? 1 : itemStart} to {endOffset} of{' '}
          {items.length}
        </p>
      </div>
    </div>
  )
}

export default Pagination
