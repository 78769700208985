import React, { useState } from 'react'
import NavTitle from './NavTitle'

const Price = ({ managePriceId }) => {
  const priceList = [
    {
      _id: 950,
      priceOne: 0.0,
      priceTwo: 49.99,
    },
    {
      _id: 951,
      priceOne: 50.0,
      priceTwo: 99.99,
    },
    {
      _id: 952,
      priceOne: 100.0,
      priceTwo: 199.99,
    },
    {
      _id: 953,
      priceOne: 200.0,
      priceTwo: 399.99,
    },
    {
      _id: 954,
      priceOne: 400.0,
      priceTwo: 599.99,
    },
    {
      _id: 955,
      priceOne: 600.0,
      priceTwo: 1000.0,
    },
  ]
  const [idHolder, setIdholder] = useState(0)

  const managePrice = (id) => {
    const element = document.getElementById(id)
    console.log(element)
    if (idHolder === id) {
      element.style.backgroundColor = ''
      element.style.color = ''
      managePriceId(0)
      setIdholder(0)
    } else {
      if (idHolder != 0) {
        const element2 = document.getElementById(idHolder)
        element2.style.backgroundColor = ''
        element2.style.color = ''
      }
      element.style.backgroundColor = 'rgb(170,170,166)'

      element.style.color = 'white'
      managePriceId(id)
      setIdholder(id)
    }
  }
  return (
    <div className="cursor-pointer">
      <NavTitle title="Shop by Price" icons={false} />
      <div className="font-titleFont">
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
          {priceList.map((item) => (
            <li
              key={item._id}
              id={item._id}
              className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300 text-xl py-2 px-1 rounded-lg shadow-sm"
              onClick={() => managePrice(item._id)}
            >
              ${item.priceOne.toFixed(2)} - ${item.priceTwo.toFixed(2)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Price
