import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  newArrFive,
  otherOne,
  otherTwo,
  otherThree,
  otherFour,
  otherFive,
  otherSix,
  otherSeven,
  otherEight,
  otherNine,
  otherTen,
  otherEleven,
  otherTwelve,
  otherThirteen,
  otherFourteen,
  otherFifteen,
  otherSixteen,
  otherSeventeen,
  otherNineteen,
  otherTwenty,
  otherTwentyOne,
  otherEighteen,
} from '../assets/images/index'

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: 'Home',
    link: '/',
  },
  {
    _id: 1002,
    title: 'Shop',
    link: '/shop',
  },
  {
    _id: 1003,
    title: 'About',
    link: '/about',
  },
  {
    _id: 1004,
    title: 'Contact',
    link: 'contact',
  },
]
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  /* {
    _id: '201',
    category: 'Travel & Accessories',
    brandName: 'Thor Cap',
    img: spfOne,
    productName: 'Thor Logo Limited Edition Kids Cap ',
    price: '35.00',
    color: 'Blank and White',
    badge: true,
    des: 'A stylish and limited edition Thor-themed snapback cap designed for children, featuring a grey and black color scheme with UV protection. The cap boasts the iconic Thor logo, making it a fun and practical accessory for young fans.',
  },
  {
    _id: '202',
    category: 'Home Decor',
    brandName: 'CapCraftersaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
    img: newArrFour,
    productName: 'CapCrafters Brown Tea Table',
    price: '59.00',
    color: 'Gray',
    badge: true,
    des: 'Transform your tea-time experience with "CapCrafters Brown Tea Table" an exquisite online destination for premium tea accessories and curated tablescape collections. Explore our curated selection of fine teas, elegant tableware, and accessories, creating the perfect ambiance for your tea gatherings. Elevate your tea rituals with CapCrafters.',
  },
  {
    _id: '203',
    category: 'Home Decor',
    brandName: 'Godinger',
    img: newArrFive,
    productName:
      'Godinger Stemless Wine Glasses - European Made, Set of 4 - 17oz',
    price: '35.00',
    color: 'Blank and White',
    badge: true,
    des: 'These stemless wine glasses, exquisitely crafted in a centuries-old Czech Republic crystal factory, boast a timeless and sleek design that adds class and brilliance to any table. With a wide bowl for optimal wine aeration, lead-free crystal composition, and dishwasher-safe convenience, this set of 4 glasses from Godinger offers both elegance and practicality.',
  },
  {
    _id: '204',
    category: 'Furniture & Organization',
    brandName: 'Armen',
    img: bestSellerThree,
    productName:
      'Armen Living Jaguar Dining Chair in Grey Fabric and Walnut Wood Finish',
    price: '75.00',
    color: 'Mixed',
    badge: false,
    des: 'The Armen Living Jaguar side chair combines mid-century modern and contemporary design elements, making it a versatile and captivating addition to any space. With luxuriously plush upholstery, a durable walnut wood finish, and exceptional support, this chair offers both comfort and style while boasting a 1-year warranty for added peace of mind.',
  }, */
]
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    category: 'Electronics',
    brandName: 'Apple',
    img: newArrOne,
    productName: 'Apple iPhone 15 Plus, 128GB, Black',
    price: '770.00',
    color: 'Blank and White',
    badge: true,
    des: 'The iPhone features a stunning 6.7-inch Super Retina XDR display with an aluminum body and color-infused glass back, providing a visually immersive experience. Equipped with an A16 Bionic chip, an advanced dual-camera system capable of super-high-resolution photos, and innovative features like Emergency SOS via satellite, this device seamlessly blends cutting-edge technology with practical functionality.',
  },
  {
    _id: 1002,
    category: 'Electronics',
    brandName: 'Apple',
    img: newArrTwo,
    productName: 'Apple Watch SE (2nd Gen) Smartwatch ',
    price: '250.00',
    color: 'Black',
    badge: true,
    des: 'The Apple Watch SE packs all the essential features to keep you motivated, active, and connected, boasting enhanced health and safety functionalities like Fall Detection, Crash Detection, and Emergency SOS. Not only is it a powerful fitness partner with advanced workout metrics, but it is also carbon neutral, featuring a swimproof and stylish design that seamlessly integrates with Apple devices and services, making it a comprehensive and environmentally conscious smartwatch option.',
  },
  {
    _id: 1003,
    category: 'Furniture & Organization',
    brandName: 'Vinssin',
    img: newArrThree,
    productName: 'Vinssin Large Storage Gray Baskets',
    price: '45.00',
    color: 'Mixed',
    badge: true,
    des: 'This fashionable and durable storage basket in a stylish grey design is made from high-quality materials, featuring a sturdy metal rod frame and comfortable cotton handles for easy carrying. With collapsible capabilities for space-saving storage, it serves multiple purposes, providing an organized solution for storing various items in bedrooms, living rooms, kitchens, offices, and more, while also being easy to clean and environmentally friendly.',
  },
  {
    _id: 1004,
    category: 'Home Decor',
    brandName: 'Malissa & Doug',
    img: newArrFour,
    productName: 'Melissa & Doug Soft Taco Toys For Babies',
    price: '59.00',
    color: 'Mixed',
    badge: true,
    des: 'The Melissa & Doug Soft Taco Fill-and-Spill Toy is a delightful 12-piece playset designed for infants and toddlers to explore textures and sounds. Featuring a soft foam taco shell and various fabric pieces, this toy encourages sensory, fine motor skills, and problem-solving abilities, making it an ideal gift for hands-on, screen-free play and developmental skill-building for children aged 6 months to 12 months.',
  },
  {
    _id: 1005,
    category: 'Home Decor',
    brandName: 'Godinger',
    img: newArrFive,
    productName: 'Godinger Stemless Wine Glasses - Set of 4 - 17oz',
    price: '35.00',
    color: 'Blank and White',
    badge: true,
    des: 'These stemless wine glasses, exquisitely crafted in a centuries-old Czech Republic crystal factory, boast a timeless and sleek design that adds class and brilliance to any table. With a wide bowl for optimal wine aeration, lead-free crystal composition, and dishwasher-safe convenience, this set of 4 glasses from Godinger offers both elegance and practicality.',
  },
  {
    _id: 1006,
    category: 'Electronics',
    brandName: 'Anker',
    img: spfThree,
    productName: 'Anker Soundcore Life Q30 Headphones',
    price: '99.00',
    color: 'Black',
    badge: false,
    des: 'The Anker Soundcore Life Q30 headphones offer an immersive audio experience with hybrid active noise cancellation, utilizing dual microphones to eliminate up to 95% of low-frequency ambient noise. Users can tailor the noise cancellation to specific environments, and the headphones boast Hi-Res 40mm drivers for exceptional audio clarity up to 40kHz. With an impressive 40-hour playtime in noise-canceling mode, customizable ANC modes, and pressure-free comfort with memory foam earcups, the Life Q30 provides a premium and versatile listening solution for extended periods.',
  },
  {
    _id: 1007,
    category: 'Travel & Accessories',
    brandName: 'Ray-Ban',
    img: spfFour,
    productName: 'Ray-Ban RB4184 Square Sunglasses, Black',
    price: '89.00',
    color: 'Black',
    badge: false,
    des: 'The Ray-Ban RB4184 Square Sunglasses in Black/Green, with a 54 mm lens size, offer a restructured take on the iconic Wayfarer design. Featuring signature temples and a larger rectangular shape, these sunglasses are not only stylish but also prescription-ready, providing both fashion and functionality for any wearer.',
  },
  {
    _id: 1008,
    category: 'Home Decor',
    brandName: 'Danpinera',
    img: spfTwo,
    productName: 'Danpinera Round Side Table',
    price: '220.00',
    color: 'Black',
    badge: false,
    des: 'Transform your tea-time experience with "Danpinera Round Side Table" an exquisite online destination for premium tea accessories and curated tablescape collections. Explore our curated selection of fine teas, elegant tableware, and accessories, creating the perfect ambiance for your tea gatherings. Elevate your tea rituals with Danpinera.',
  },
  {
    _id: 1009,
    category: 'Travel & Accessories',
    brandName: 'Thor Cap',
    img: spfOne,
    productName: 'Thor Logo Limited Edition Kids Cap',
    price: '35.00',
    color: 'Black and White',
    badge: false,
    des: 'A stylish and limited edition Thor-themed snapback cap designed for children, featuring a grey and black color scheme with UV protection. The cap boasts the iconic Thor logo, making it a fun and practical accessory for young fans.',
  },
  {
    _id: 1010,
    category: 'Home Decor',
    brandName: 'Flower Vase',
    img: bestSellerOne,
    productName: 'Flower Vase for Modern Table Shelf Home Decor',
    price: '35.00',
    color: 'White',
    badge: false,
    des: 'Enhance your home with this Minimalist Ceramic Vase. Its clean, elegant design effortlessly fits into any space—ideal for modern tables, shelves, fireplaces, bedrooms, kitchens, living rooms, or office desks. Crafted from high-quality white porcelain with a uniform texture and special frosted handfeel, this versatile centerpiece adds sophistication. Perfect for those desiring a minimalist yet chic addition to elevate their living spaces.',
  },
  {
    _id: 1011,
    category: 'Home Decor',
    brandName: 'Vinssin',
    img: newArrThree,
    productName: 'Vinssin Large Storage Gray Baskets',
    price: '45.00',
    color: 'Mixed',
    badge: false,
    des: 'This fashionable and durable storage basket in a stylish grey design is made from high-quality materials, featuring a sturdy metal rod frame and comfortable cotton handles for easy carrying. With collapsible capabilities for space-saving storage, it serves multiple purposes, providing an organized solution for storing various items in bedrooms, living rooms, kitchens, offices, and more, while also being easy to clean and environmentally friendly.',
  },
  {
    _id: 1012,
    category: 'Home Decor',
    brandName: 'Melissa & Doug',
    img: newArrFour,
    productName: 'Melissa & Doug Soft Taco Toys For Babies',
    price: '59.00',
    color: 'Mixed',
    badge: false,
    des: 'feswefThe Melissa & Doug Soft Taco Fill-and-Spill Toy is a delightful 12-piece playset designed for infants and toddlers to explore textures and sounds. Featuring a soft foam taco shell and various fabric pieces, this toy encourages sensory, fine motor skills, and problem-solving abilities, making it an ideal gift for hands-on, screen-free play and developmental skill-building for children aged 6 months to 12 months.',
  },
  {
    _id: 1013,
    category: 'Home Decor',
    brandName: 'Flower Vase',
    img: bestSellerOne,
    productName: 'Flower Vase for Modern Table Shelf Home Decor',
    price: '35.00',
    color: 'White',
    badge: false,
    des: 'Enhance your home with this Minimalist Ceramic Vase. Its clean, elegant design effortlessly fits into any space—ideal for modern tables, shelves, fireplaces, bedrooms, kitchens, living rooms, or office desks. Crafted from high-quality white porcelain with a uniform texture and special frosted handfeel, this versatile centerpiece adds sophistication. Perfect for those desiring a minimalist yet chic addition to elevate their living spaces.',
  },

  {
    _id: 1014,
    category: 'Travel & Accessories',
    brandName: 'SHRRADOO',
    img: bestSellerTwo,
    productName: 'SHRRADOO Extra Large Travel Laptop Backpack',
    price: '69.00',
    color: 'Black',
    badge: false,
    des: 'The SHRRADOO Extra Large 52L Travel Laptop Backpack is a sleek and spacious black backpack designed for professionals, students, and travelers. With a 52-liter capacity, dedicated laptop compartment for 17-inch laptops, USB charging port, and airline approval, it offers both style and functionality for organized and convenient use on the go.',
  },
  {
    _id: 1015,
    category: 'Furniture & Organization',
    brandName: 'Armen',
    img: bestSellerThree,
    productName: 'Armen Living Jaguar Dining Chair',
    price: '75.00',
    color: 'Mixed',
    badge: false,
    des: 'The Armen Living Jaguar side chair combines mid-century modern and contemporary design elements, making it a versatile and captivating addition to any space. With luxuriously plush upholstery, a durable walnut wood finish, and exceptional support, this chair offers both comfort and style while boasting a 1-year warranty for added peace of mind.',
  },
  {
    _id: 1016,
    category: 'Furniture & Organization',
    brandName: 'G Keni',
    img: bestSellerFour,
    productName: 'G Keni Nursery Night Light for Baby',
    price: '119.00',
    color: 'White',
    badge: false,
    des: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.',
  },
  {
    _id: 1017,
    category: 'Electronics',
    brandName: 'JBL',
    img: otherOne,
    productName: 'JBL Vibe 200TWS True Wireless Earbuds',
    price: '29.90',
    color: 'Black',
    badge: false,
    des: 'Experience the ultimate in sound quality with the JBL Vibe 200TWS earbuds featuring deep bass powered by 8mm drivers. With 20 hours of combined playback, touch controls for calls and voice assistant, and a comfortable ergonomic fit, these earbuds are designed to enhance your music and communication experience.',
  },
  {
    _id: 1018,
    category: 'Travel & Accessories',
    brandName: 'Time Resistance',
    img: otherTwo,
    productName: 'Time Resistance Men Organizer Wrist Bag ',
    price: '118.00',
    color: 'Brown',
    badge: false,
    des: 'Make a bold statement with our handmade leather clutch, meticulously crafted by skilled artisans for unrivaled quality and timeless design. Crafted from full-grain Italian cowhide leather, this clutch offers durability, sophistication, and practicality, making it the perfect accessory for the man on the go.',
  },
  {
    _id: 1019,
    category: 'Kitchen & Appliances',
    brandName: 'Hamilton',
    img: otherThree,
    productName: 'Hamilton Beach Electric Tea Kettle',
    price: '49.90',
    color: 'Metalic',
    badge: false,
    des: 'Boil water quickly and safely with this electric kettle featuring a powerful rapid boil system, making it ideal for college dorms, offices, or any kitchen. The cord-free design, automatic shutoff with boil dry protection, and convenient features like an easy pour spout and removable mesh filter ensure a hassle-free and efficient boiling experience.',
  },
  {
    _id: 1020,
    category: 'Travel & Accessories',
    brandName: 'Blink Outdoor',
    img: otherFour,
    productName: 'Blink Outdoor 4 Smart Security Camera',
    price: '149.00',
    color: 'Black',
    badge: false,
    des: 'The Blink Outdoor 4 smart security camera, paired with a one-year Blink Subscription Plus Plan, offers robust home protection with its wire-free design and advanced features. Experience 1080p HD live view, infrared night vision, two-way audio, and efficient motion detection, including person detection through embedded computer vision, all while enjoying the convenience of a two-year battery life and flexible storage options with cloud and local backups.',
  },
  {
    _id: 1021,
    category: 'Travel & Accessories',
    brandName: 'Sekonda',
    img: otherFive,
    productName: "Sekonda Women's Quartz Watch",
    price: '32.00',
    color: 'Mixed',
    badge: false,
    des: "Elevate your style with the Sekonda Women's Quartz Watch featuring a luxurious rose gold plated case and a silver sunray dial adorned with elegant Roman numerals. Complemented by a white PU strap and backed by a 2-year guarantee, this timepiece combines sophistication and durability for a timeless accessory.",
  },
  {
    _id: 1022,
    category: 'Home Decor',
    brandName: 'Childom',
    img: otherSix,
    productName: 'Childom Artificial Preserved Flowers',
    price: '19.00',
    color: 'Mixed',
    badge: false,
    des: "Celebrate love and romance with the vibrant and everlasting Valentines Rose Flowers Gift in a Glass Dome. Perfect for her on Valentine's Day, birthdays, or special occasions, these colorful preserved roses, complete with LED strip lights, symbolize appreciation, best wishes, and passionate romance, presented beautifully in a glass dome with a thoughtful card and elegant packaging.",
  },
  {
    _id: 1023,
    category: 'Kitchen & Appliances',
    brandName: 'Keurig',
    img: otherSeven,
    productName: 'Keurig K-Express Coffee Maker',
    price: '69.00',
    color: 'Black',
    badge: false,
    des: 'Elevate your coffee experience with the Strong Brew feature of this versatile coffee maker, intensifying the boldness and flavor of your brew. With the convenience of brewing 8, 10, or 12 oz. cups, a multiple-cup water reservoir, and travel mug compatibility, this efficient coffee maker ensures a fast, fresh, and customizable brewing experience tailored to your preferences.',
  },
  {
    _id: 1024,
    category: 'Kitchen & Appliances',
    brandName: 'iHome',
    img: otherEight,
    productName: 'iHome Bluetooth Alarm Clock',
    price: '29.00',
    color: 'White',
    badge: false,
    des: "The iHome iOP235 Digital Alarm Clock is a sleek and customizable Bluetooth device that serves as a dual alarm, Bluetooth speaker, and USB charger all in one. With versatile features like personalized alarm schedules, adjustable brightness levels, and a built-in sleep timer, it's the perfect gift for anyone seeking a stylish and functional addition to their bedroom or home office.",
  },
  {
    _id: 1025,
    category: 'Electronics',
    brandName: 'Vinyl',
    img: otherNine,
    productName: 'Vinyl Record Player with Speaker Bluetooth',
    price: '79.00',
    color: 'White',
    badge: false,
    des: 'Experience the best of both worlds with this versatile Bluetooth-enabled record player featuring wireless input, USB playback, and solid premium sound. With an elegant wooden craftsmanship, adjustable needle pressure, and 3-speed playback, this vintage-style player makes for an ideal gift, seamlessly blending nostalgia with modern technology for vinyl enthusiasts or those starting their music journey.',
  },
  {
    _id: 1026,
    category: 'Kitchen & Appliances',
    brandName: 'Chefman',
    img: otherTen,
    productName: 'Chefman 2-Slice Pop-Up Stainless Steel Toaster',
    price: '31.00',
    color: 'Metalic',
    badge: false,
    des: 'Upgrade your toaster with the Chefman 2 Slice Pop-Up Toaster, featuring a modern brushed stainless-steel exterior that adds a timeless touch to your kitchen. With 7 shade settings, extra-wide slots, and versatile functions like bagel, reheat, and defrost, this toaster offers customizable toasting options for various preferences, while safety features and easy cleaning make it a convenient addition to your countertop.',
  },
  {
    _id: 1027,
    category: 'Furniture & Organization',
    brandName: 'HOOBRO',
    img: otherEleven,
    productName: 'HOOBRO Shoe Organizer Storage Bench',
    price: '37.00',
    color: 'Brown',
    badge: false,
    des: 'Experience a blend of ancient, retro style and industrial design with this unique shoe rack bench, featuring sturdy metal frames and premium board construction. Tailored to perfection at 29.5"L x 11.2"W x 19"H, this 3-tier shoe rack not only adds charm to your entryway but also offers versatile functionality as a stylish seat, ensuring a tidy and organized home with effortless assembly.',
  },
  {
    _id: 1028,
    category: 'Electronics',
    brandName: 'Logitech',
    img: otherTwelve,
    productName: 'Logitech MX Keys Wireless Keyboard',
    price: '109.00',
    color: 'Black',
    badge: false,
    des: 'Elevate your typing experience with the Logitech Perfect Stroke Keys, designed to match the shape of your fingertips for satisfying feedback with every tap. Crafted for comfort, stability, and precision, this keyboard offers smart illumination, cross-computer control, and multi-device compatibility, making it a versatile and efficient choice for users across various operating systems.',
  },
  {
    _id: 1029,
    category: 'Electronics',
    brandName: 'LEVOIT',
    img: otherThirteen,
    productName: 'LEVOIT Air Purifier for Home Allergies Pets Hair',
    price: '84.00',
    color: 'White',
    badge: false,
    des: "Experience superior air purification with the Levoit Core 300-P Air Purifier, featuring a high-performance 45W motor and a strong filtration efficiency of 99.97% for ultrafine particles. With a sleek design, whisper-quiet operation in sleep mode, and multiple filter choices, this purifier, including Levoit's original filter, ensures fresh, clean air in spaces up to 1095 ft² while seamlessly blending into your home decor.",
  },
  {
    _id: 1030,
    category: 'Electronics',
    brandName: 'FITINDEX',
    img: otherFourteen,
    productName: 'FITINDEX Smart Scale for Body Weight',
    price: '18.00',
    color: 'White',
    badge: false,
    des: 'Simplify your fitness journey with the FITINDEX Smart Weighing Scale, designed for easy installation and setup, making it perfect for beginners. With advanced features such as unlimited user support, baby weighing mode, and 13 body composition measurements, including weight, body fat ratio, and more, this digital scale syncs seamlessly with fitness apps, providing accurate data for a comprehensive understanding of your health and fitness progress.',
  },
  {
    _id: 1031,
    category: 'Electronics',
    brandName: 'Samsung',
    img: otherFifteen,
    productName: 'SAMSUNG Galaxy Tab A9+ 10.9” 128GB Tablet',
    price: '219.00',
    color: 'Black',
    badge: false,
    des: 'Immerse yourself in family-sized fun with the Samsung Galaxy Tab A9+, featuring a bright and engaging 11" screen and a smooth refresh rate of up to 90Hz for an enjoyable multimedia experience. With rich Dolby Atmos-powered quad speakers, enhanced storage up to 128GB, and seamless multitasking capabilities, this slim, light, and durable tablet is designed for entertainment, productivity, and convenience on the go.',
  },
  {
    _id: 1032,
    category: 'Kitchen & Appliances',
    brandName: 'SYBO',
    img: otherSixteen,
    productName: 'SYBO 12-Cup Drip Coffee Maker',
    price: '259.00',
    color: 'Black',
    badge: false,
    des: "Brew your perfect cup with the SYBO Commercial Grade Drip Coffee Maker, featuring a 12-cup capacity and efficient brewing in under 10 minutes. With its easy operation, drip-free carafes, multi-stream spray head, and safety certifications, this coffee machine ensures a mess-free, quality brewing experience, backed by SYBO's 30 years of stainless steel kitchenware expertise.",
  },
  {
    _id: 1033,
    category: 'Electronics',
    brandName: 'Apple',
    img: otherSeventeen,
    productName: 'Apple 2020 MacBook Air Laptop M1',
    price: '749.00',
    color: 'Silver',
    badge: false,
    des: 'Experience unparalleled performance and efficiency with the MacBook Air featuring the Apple M1 chip, delivering up to 3.5x faster performance while maintaining an impressive 18 hours of battery life. With its stunning 13.3-inch Retina display, superfast memory, and seamless compatibility with a vast array of apps, the MacBook Air redefines ease of use and productivity in the world of laptops.',
  },
  {
    _id: 1034,
    category: 'Electronics',
    brandName: 'Eufy',
    img: otherEighteen,
    productName: 'Eufy RoboVac 11S MAX, Robot Vacuum Cleaner',
    price: '249.00',
    color: 'Black',
    badge: false,
    des: 'Effortlessly maintain a spotless home with the eufy RoboVac 11S MAX, featuring a slim design, extended 100-minute runtime, and BoostIQ Technology for intelligent suction adjustments. With advanced features like anti-scratch protection, infrared sensors, and automatic recharging, this robot vacuum ensures efficient and hassle-free cleaning, providing a quiet yet powerful performance for your floors.',
  },
  {
    _id: 1035,
    category: 'Electronics',
    brandName: 'Apple',
    img: otherNineteen,
    productName: 'Apple AirPods Pro 2nd Gen Wireless Ear Buds',
    price: '249.00',
    color: 'White',
    badge: false,
    des: "Experience an enhanced audio journey with AirPods Pro, featuring Apple's H2 chip for intelligent noise cancellation and premium sound quality. With customizable fit, advanced active noise cancellation, and personalized spatial audio, these wireless earbuds provide an immersive and tailored listening experience, complemented by touch controls and a durable, water-resistant design.",
  },
  {
    _id: 1036,
    category: 'Electronics',
    brandName: 'Apple',
    img: otherTwenty,
    productName: 'Apple AirPods Max Wireless Headphones',
    price: '499.00',
    color: 'Black',
    badge: false,
    des: 'Immerse yourself in the world of premium audio with AirPods Max, featuring a dynamic driver, active noise cancellation, and computational audio for unparalleled listening experiences. Designed with an acoustic-first approach, these over-ear headphones provide a comfortable fit, magical pairing, and up to 20 hours of battery life, making every moment a cinematic and personalized sound journey.',
  },
  {
    _id: 1037,
    category: 'Electronics',
    brandName: 'Logitech',
    img: otherTwentyOne,
    productName: 'Logitech MX Master 3S Wireless Mouse',
    price: '97.00',
    color: 'Black',
    badge: false,
    des: 'Experience precision and versatility with the MX Master 3S, a wireless mouse that boasts an 8000 DPI sensor for exceptional tracking on any surface, including glass. With its quiet clicks, ergonomic design, and advanced customization options, this mouse is designed to enhance your workflow and deliver a comfortable and efficient user experience, making it an ideal companion for productivity across multiple devices and operating systems. ',
  },
  {
    _id: 1038,
    category: 'Furniture & Organization',
    brandName: 'CapCrafters',
    img: newArrFour,
    productName: 'CapCrafters Brown Tea Table',
    price: '59.00',
    color: 'Mixed',
    badge: true,
    des: 'Transform your tea-time experience with "CapCrafters Brown Tea Table" an exquisite online destination for premium tea accessories and curated tablescape collections. Explore our curated selection of fine teas, elegant tableware, and accessories, creating the perfect ambiance for your tea gatherings. Elevate your tea rituals with CapCrafters.',
  },
  {
    _id: 1039,
    category: 'Furniture & Organization',
    brandName: 'Danpinera',
    img: spfTwo,
    productName: 'Danpinera Round Side Table',
    price: '79.00',
    color: 'Mixed',
    badge: false,
    des: 'Transform your tea-time experience with "Danpinera Round Side Table" an exquisite online destination for premium tea accessories and curated tablescape collections. Explore our curated selection of fine teas, elegant tableware, and accessories, creating the perfect ambiance for your tea gatherings. Elevate your tea rituals with Danpinera.',
  },
]
// =================== PaginationItems End here =================
