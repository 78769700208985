import React from 'react'
import Brand from './shopBy/Brand'
import Category from './shopBy/Category'
import Color from './shopBy/Color'
import Price from './shopBy/Price'

const ShopSideNav = ({ managePriceId, manageCategoryId, manageBrandId }) => {
  return (
    <div className="w-full flex flex-col gap-20">
      <Category icons={false} manageCategoryId={manageCategoryId} />
      {/*  <Color /> */}
      <Brand manageBrandId={manageBrandId} />
      <Price managePriceId={managePriceId} />
    </div>
  )
}

export default ShopSideNav
