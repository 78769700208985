import React from 'react'
import { Link } from 'react-router-dom'
const ShopNow = () => {
  return (
    <Link
      to="/offer"
      className="bg-primeColor text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-black duration-300 font-bold grid place-content-center"
    >
      Shop Now
    </Link>
  )
}

export default ShopNow
