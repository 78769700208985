import React, { useState } from 'react'
import { motion } from 'framer-motion'
import NavTitle from './NavTitle'

const Brand = ({ manageBrandId }) => {
  const [showBrands, setShowBrands] = useState(true)
  const [idHolder, setIdholder] = useState(0)
  const manageCategory = (id) => {
    const element = document.getElementById(id)
    console.log(element)
    if (idHolder === id) {
      element.style.backgroundColor = ''
      element.style.color = ''
      manageBrandId(0)
      setIdholder(0)
    } else {
      if (idHolder != 0) {
        const element2 = document.getElementById(idHolder)
        element2.style.backgroundColor = ''
        element2.style.color = ''
      }
      element.style.backgroundColor = 'rgb(170,170,166)'

      element.style.color = 'white'
      manageBrandId(id)
      setIdholder(id)
    }
  }
  const brands = [
    {
      _id: 9006,
      title: 'Apple',
    },
    {
      _id: 9007,
      title: 'Samsung',
    },
    {
      _id: 9008,
      title: 'Anker',
    },
    {
      _id: 9009,
      title: 'Logitech',
    },
    {
      _id: 9010,
      title: 'Others',
    },
  ]

  return (
    <div>
      <div
        onClick={() => setShowBrands(!showBrands)}
        className="cursor-pointer"
      >
        <NavTitle title="Shop by Brand" icons={false} />
      </div>

      <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
        {brands.map((item) => (
          <button
            key={item._id}
            id={item._id}
            className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300 text-xl py-2 px-1 rounded-lg shadow-sm"
            onClick={() => manageCategory(item._id)}
          >
            {item.title}
          </button>
        ))}
      </ul>
    </div>
  )
}

export default Brand
