import React from 'react'
import Heading from '../Products/Heading'
import Product from '../Products/Product'
import {
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
} from '../../../assets/images/index'

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1011"
          img={bestSellerOne}
          productName="Flower Vase for Modern Table Shelf Home Decor"
          category="Home Decor"
          brandName="Flower Vase"
          price="35.00"
          color="White"
          badge={false}
          des="Enhance your home with this Minimalist Ceramic Vase. Its clean, elegant design effortlessly fits into any space—ideal for modern tables, shelves, fireplaces, bedrooms, kitchens, living rooms, or office desks. Crafted from high-quality white porcelain with a uniform texture and special frosted handfeel, this versatile centerpiece adds sophistication. Perfect for those desiring a minimalist yet chic addition to elevate their living spaces."
        />
        <Product
          _id="1012"
          img={bestSellerTwo}
          productName="SHRRADOO Extra Large Travel Laptop Backpack"
          category="Travel & Accessories"
          brandName="SHRRADOO"
          price="69.00"
          color="Black"
          badge={false}
          des="The SHRRADOO Extra Large 52L Travel Laptop Backpack is a sleek and spacious black backpack designed for professionals, students, and travelers. With a 52-liter capacity, dedicated laptop compartment for 17-inch laptops, USB charging port, and airline approval, it offers both style and functionality for organized and convenient use on the go."
        />
        <Product
          _id="1013"
          img={bestSellerThree}
          productName="Armen Living Jaguar Dining Chair"
          category="Furniture & Organization"
          brandName="Armen"
          price="75.00"
          color="Mixed"
          badge={false}
          des="
          The Armen Living Jaguar side chair combines mid-century modern and contemporary design elements, making it a versatile and captivating addition to any space. With luxuriously plush upholstery, a durable walnut wood finish, and exceptional support, this chair offers both comfort and style while boasting a 1-year warranty for added peace of mind."
        />
        <Product
          _id="1014"
          img={bestSellerFour}
          productName="G Keni Nursery Night Light for Baby"
          category="Furniture & Organization"
          brandName="G Keni"
          price="119.00"
          color="White"
          badge={false}
          des="The G Keni Nursery Night Light is a portable LED touch lamp designed for both babies and adults, offering a soft and dimmable warm light ideal for bedtime routines, breastfeeding, and as a sleep aid. With USB rechargeability, it provides convenient and soothing illumination while ensuring a gentle and eye-caring ambiance in any nursery or bedside setting."
        />
      </div>
    </div>
  )
}

export default BestSellers
