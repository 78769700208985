import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/pageProps/Breadcrumbs'
import Pagination from '../../components/pageProps/shopPage/Pagination'
import ProductBanner from '../../components/pageProps/shopPage/ProductBanner'
import ShopSideNav from '../../components/pageProps/shopPage/ShopSideNav'

const Shop = () => {
  const [itemsPerPage, setItemsPerPage] = useState(12)
  const [sortBy, setSortBy] = useState(0)

  const sortByFromBanner = (sortBy) => {
    setSortBy(sortBy)
  }
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage)
  }
  const [priceId, setPriceId] = useState(0)
  const [categoryId, setCategoryId] = useState(0)
  const [brandId, setBrandId] = useState(0)

  function managePriceId(prcId) {
    setPriceId(prcId)
  }
  function manageCategoryId(ctgrId) {
    setCategoryId(ctgrId)
  }
  function manageBrandId(brId) {
    setBrandId(brId)
  }

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Products" />
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-20 gap-10">
        <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <ShopSideNav
            managePriceId={managePriceId}
            manageCategoryId={manageCategoryId}
            manageBrandId={manageBrandId}
          />
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
          <ProductBanner
            itemsPerPageFromBanner={itemsPerPageFromBanner}
            sortByFromBanner={sortByFromBanner}
          />
          <Pagination
            itemsPerPage={itemsPerPage}
            priceId={priceId}
            categoryId={categoryId}
            brandId={brandId}
          />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  )
}

export default Shop
