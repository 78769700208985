import React from 'react'
import Slider from 'react-slick'
import Heading from '../Products/Heading'
import Product from '../Products/Product'
import {
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  newArrFive,
} from '../../../assets/images/index'
import SampleNextArrow from './SampleNextArrow'
import SamplePrevArrow from './SamplePrevArrow'

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }
  return (
    <div className="w-full pb-16">
      <Heading heading="New Arrivals" />
      <Slider {...settings}>
        <div className="px-2">
          <Product
            _id="100001"
            img={newArrOne}
            productName="Apple iPhone 15 Plus, 128GB, Black"
            brandName="Apple"
            category="Electronics"
            price="770.00"
            color="Black"
            badge={true}
            des="The iPhone features a stunning 6.7-inch Super Retina XDR display with an aluminum body and color-infused glass back, providing a visually immersive experience. Equipped with an A16 Bionic chip, an advanced dual-camera system capable of super-high-resolution photos, and innovative features like Emergency SOS via satellite, this device seamlessly blends cutting-edge technology with practical functionality."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100002"
            img={newArrTwo}
            productName="Apple Watch SE (2nd Gen) Smartwatch "
            brandName="Apple"
            category="Electronics"
            price="250.00"
            color="Black"
            badge={true}
            des="The Apple Watch SE packs all the essential features to keep you motivated, active, and connected, boasting enhanced health and safety functionalities like Fall Detection, Crash Detection, and Emergency SOS. Not only is it a powerful fitness partner with advanced workout metrics, but it is also carbon neutral, featuring a swimproof and stylish design that seamlessly integrates with Apple devices and services, making it a comprehensive and environmentally conscious smartwatch option."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100003"
            img={newArrThree}
            productName="Vinssin Large Storage Gray Baskets"
            category="Furniture & Organization"
            brandName="Vinssin"
            price="45.00"
            color="Mixed"
            badge={true}
            des="This fashionable and durable storage basket in a stylish grey design is made from high-quality materials, featuring a sturdy metal rod frame and comfortable cotton handles for easy carrying. With collapsible capabilities for space-saving storage, it serves multiple purposes, providing an organized solution for storing various items in bedrooms, living rooms, kitchens, offices, and more, while also being easy to clean and environmentally friendly."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100004"
            img={newArrFour}
            productName="Melissa & Doug Soft Taco Toys For Babies"
            category="Home Decor"
            brandName="Malissa & Doug"
            price="59.00"
            color="Mixed"
            badge={true}
            des="The Melissa & Doug Soft Taco Fill-and-Spill Toy is a delightful 12-piece playset designed for infants and toddlers to explore textures and sounds. Featuring a soft foam taco shell and various fabric pieces, this toy encourages sensory, fine motor skills, and problem-solving abilities, making it an ideal gift for hands-on, screen-free play and developmental skill-building for children aged 6 months to 12 months."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100005"
            img={newArrFive}
            productName="Godinger Stemless Wine Glasses - Set of 4 - 17oz"
            category="Home Decor"
            brandName="Godinger"
            price="250.00"
            color="Black"
            badge={true}
            des="These stemless wine glasses, exquisitely crafted in a centuries-old Czech Republic crystal factory, boast a timeless and sleek design that adds class and brilliance to any table. With a wide bowl for optimal wine aeration, lead-free crystal composition, and dishwasher-safe convenience, this set of 4 glasses from Godinger offers both elegance and practicality."
          />
        </div>
      </Slider>
    </div>
  )
}

export default NewArrivals
